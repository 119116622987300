export const theme = () => {
  return {
    colors: {
      background: "#ffffff",
      backgroundDark: "#F5F5F5",
      black: "#111",
      blackOpac: "rgba(0, 0, 0, 0.8)",
      white: "#fff",
      whiteOpac: "rgba(255, 255, 255, 0.1)",
      success: "#03a678",
      errors: "#de4444",
      lightBorder: "#f1edea",
      lightGrey: "#ccc",
      mediumGrey: "#BCBCBC",
      darkGrey: "#666",
      disabledBackground: "#ccc",
      disabledTextColor: "#888"
    },
    swatches: {
      white: "#ffffff",
      black: "#000000",
      offWhite: "#ECE5DA"
    }
  }
}
