/* eslint jsx-a11y/html-has-lang: 0 */
import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: ${(p) => (p.active ? "opacity 0.3s" : "all 0.3s")};
  z-index: ${(p) => (p.active ? 10002 : -1)};
  opacity: ${(p) => (p.active ? 1 : 0)};
`

const Backdrop = ({ children, active, handleOnClick, ...other }) => (
  <Container active={active} onClick={handleOnClick} {...other}>
    {active && (
      <Helmet>
        <html className="disableScroll" />
        <body className="disableScroll" />
      </Helmet>
    )}
    {children}
  </Container>
)

export default Backdrop
